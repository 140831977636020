import React from 'react';

import {
  Box,
  Button,
  chakra,
  Flex,
  IconButton,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';

import { useIsMobile } from 'utils/hooks';

import { useStore } from 'effector-react';
import { SessionStore } from 'effector/session/store';

import ResourcesLogo from 'res/resources.png';
import Resource1 from 'res/resources/FAA-Logo.png';
import Resource2 from 'res/resources/drone-industry-insights.png';
import Resource3 from 'res/resources/Dronegirl.png';
import Resource4 from 'res/resources/skywatch.png';
import Resource5 from 'res/resources/dronelife.png';
import Resource6 from 'res/resources/dronelaunch.png';
import Resource7 from 'res/resources/cloud_campaign.png';

import PilotHeader from 'components/modules/PilotHeader';

import { ResourcesGeneratedProps } from './Resources.props';
import { MdNotifications } from 'react-icons/md';

const RESOURCES = [
  {
    id: 1,
    title: 'Did you just buy a new drone?',
    image: Resource1,
    link: 'https://faadronezone-access.faa.gov/#/',
    content:
      'Did you just buy a new drone?  Well you have to register that drone before you are able to fly.  Visit FAA Drone Zone to learn more.',
  },
  {
    id: 2,
    title: 'Drone Industry Insights',
    image: Resource2,
    link: 'https://droneii.com/free-drone-resources',
    content:
      "Drone Industry Insights (the world's leading source for drone market research) provides free drone resources: infographics, whitepapers, & more.",
  },
  {
    id: 3,
    title: 'The Drone Girl',
    image: Resource3,
    link: 'https://www.thedronegirl.com/',
    content:
      'Stay up to date with the latest industry news and insight on which drones to purchase.',
  },
  {
    id: 4,
    title: 'Skywatch',
    image: Resource4,
    link: 'https://www.skywatch.ai/us/home',
    content:
      'Drone Insurance by SkyWatch.AI\nLiability and hull \nFlexible annual, monthly and hourly plans\nCommercial liability coverage for part-107 pilots. Trusted by thousands of drone professionals.\nservice. Rated A Excellent by AM Best',
  },
  {
    id: 5,
    title: 'FAA, United States Info',
    image: Resource1,
    link: 'https://www.faa.gov/uas',
    content:
      'Get all the basic information, news and updates on rules, regulations and certifications. ',
  },
  {
    id: 6,
    title: 'Drone Life',
    image: Resource5,
    link: 'https://dronelife.com/',
    content:
      'For all things UAS related, be sure to check out Drone Life. They stay up to date and keep people informed on rules, regulations, and more.',
  },
  {
    id: 7,
    title: 'Drone Launch Academy',
    image: Resource6,
    link: 'https://dronelaunchacademy.com/',
    content:
      "If you're just getting into drones or want to take your service offers to the next level, be sure to check out the course offered over at DLA. Our CEO was apart of the Drones to $1K course teaching his knowledge on how to grow and start a drone company. Be sure to check out our discounts in the Education section. ",
  },
  {
    id: 8,
    title: 'Social Media Marketing Tool - Cloud Campaign.',
    image: Resource7,
    link: 'https://www.cloudcampaign.com?fpr=droneadair20/',
    content:
      "Cloud Campaign helps you build a thriving drone business with AI-powered, brandable, scalable social media management software and five-star support dedicated to your business's success. Click below to access a free trial today.",
  },
];

const ResourcesView = (_: ResourcesGeneratedProps) => {
  const isMobile = useIsMobile();

  const name = useStore(SessionStore)?.session?.user.firstName || '';

  return (
    <Flex flex={1} h="100%" direction="column" bg="#f1f1f1">
      {isMobile ? null : (
        <Stack
          mx={5}
          style={{
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 30,
          }}>
          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 20,
            }}>
            <PilotHeader name={name} />
          </Stack>
        </Stack>
      )}
      <Flex
        bg="#fff"
        px={20}
        py={4}
        gridGap="20px"
        align="center"
        justify={isMobile ? 'center' : undefined}
        flexWrap="wrap"
        mt={{ base: '0', md: 10 }}
        mb={5}>
        <Image
          src={ResourcesLogo}
          maxH={{ base: '150px', md: '300px' }}
          objectFit="contain"
        />

        <Stack>
          <Text
            fontSize={{ base: 'xl', md: '4xl' }}
            fontWeight="bold"
            textAlign={{ base: 'center', md: 'left' }}>
            DroneAdair Resources
          </Text>
          <Text
            whiteSpace="pre-line"
            fontSize={{ base: '14px', md: '20px' }}
            textAlign={{ base: 'center', md: 'left' }}
            lineHeight="1.2">
            <chakra.span color="#E0B236">We are committed</chakra.span> to your
            constant improvement! Check{'\n'}our amazing resources to help you
            <chakra.span color="#E0B236"> keep growing!</chakra.span>
          </Text>
        </Stack>
      </Flex>
      <Flex
        mb="50px"
        flexWrap="wrap"
        align="center"
        justify={isMobile ? 'center' : undefined}>
        {RESOURCES.map((item, index) => (
          <Box
            cursor="pointer"
            m={4}
            key={`${item.id}-${index}`}
            maxW="250px"
            h="400px"
            w="100%"
            borderRadius="5px"
            shadow="lg"
            bg="#fff"
            display="flex"
            flexDirection="column"
            overflow="hidden">
            <Image
              w="100%"
              h="100%"
              maxH="108px"
              borderRadius="5px 5px 0px 0px"
              src={item.image}
              objectFit="cover"
            />
            <Text
              mt={2}
              p={2}
              textAlign="center"
              fontWeight="bold"
              fontSize="16px">
              {item.title}
            </Text>
            <Stack flex={1} maxH="250px" overflow="auto">
              <Text fontSize="14px" textAlign="center" my={4} mx={2}>
                {item.content}
              </Text>
            </Stack>
            <Button
              alignSelf="center"
              mt={1}
              mb={2}
              size="sm"
              as="a"
              href={item?.link}
              target="_blank">
              Learn more
            </Button>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

export default ResourcesView;
