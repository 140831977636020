import React, { FC, useRef } from 'react';

import { MdCheck } from 'react-icons/md';

import { Button, useToast } from '@chakra-ui/react';
import { Box, Flex, Text } from '@chakra-ui/layout';

import { useMediaQuery } from '@chakra-ui/media-query';

import { useCMS } from './Context';

import { useStore } from 'effector-react';
import { getUserType } from 'effector/session/store';

import { Package } from 'types/cms';
import { AccountTypes } from 'types/accountTypes';

import { SelectedIndustryStore } from './Industries';

import { MissionCreateModal } from '../PremiumMissionCreate';
import MobileCarousel from 'components/modules/MobileCarousel';
import UnableCreateMissionsModal from 'components/modules/UnableCreateMissionsModal';

const _hover = {
  zIndex: 2,
  transform: 'scale(1.10)',
  transition: 'all 0.3s ease-in-out',
};

const _active = {
  zIndex: 2,
  transform: 'scale(1)',
  transition: 'all 0.3s ease-in-out',
};

const textStyle = {
  color: '#838383',
  fontSize: 11,
  fontFamily: 'Poppins',
  fontWeight: 700,
  lineHeight: 17,
  whiteSpace: 'break-spaces',
};

const scrollBarStyles = {
  '&::-webkit-scrollbar': {
    height: '15px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
    borderRadius: '30px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#92835c50',
    borderRadius: '30px',
  },
};

export const MyPacks: FC<{ isPreview: boolean }> = ({ isPreview }) => {
  const modalRef = useRef<any>(null);
  const previewModeModalRef = useRef<any>(null);

  const selectedIndustry = useStore(SelectedIndustryStore);
  const toast = useToast();
  const { cms, brandColor, fontColor } = useCMS();
  const myPacks =
    cms?.packages?.filter((p) => p.industry?.includes(selectedIndustry)) || [];

  const [isSmall] = useMediaQuery('(max-width: 800px)');

  const onPackSelect = (selected: Package) => {
    if (!cms) {
      return;
    }
    if (isPreview) {
      return previewModeModalRef?.current.onOpen();
    }
    const accountType = getUserType();
    const isPilot = accountType === AccountTypes.pilot;

    const pilotData = {
      pilot: typeof cms.pilot === 'string' ? cms.pilot : cms?.pilot?.id,
      stripeId: typeof cms?.pilot === 'object' ? cms?.pilot?.stripeId : '',
      pack: selected,
      coordinates: {
        lat: cms.gps.coordinates?.[1],
        lng: cms.gps.coordinates?.[0],
      },
      radius: cms.radius,
      brandColor,
      industries: cms.industries,
      id: cms.id || '',
      additionalServices: cms?.additionalServices,
      missionIndustry: selectedIndustry.toUpperCase(),
      company: cms?.about?.companyName || '',
    };

    if (isPilot && !isPreview) {
      return toast({
        status: 'error',
        title: 'Error',
        description:
          'You are currently logged as a pilot, please log in with a client account to create missions.',
        isClosable: true,
        duration: 9000,
      });
    }

    modalRef.current?.onOpen?.(pilotData);
  };

  const shouldBeSmallSize = myPacks.length <= 3;
  const elWidth = 'max(350px, 30vw)';
  const elHeight = 'max(200px, 20vw)';

  return (
    <Flex
      mb={{ base: 4, md: 10 }}
      direction="column"
      justifyContent="center"
      overflowX="auto">
      {isSmall ? (
        <MobileCarousel
          onPackSelect={onPackSelect}
          cms={cms}
          brandColor={brandColor}
          fontColor={fontColor}
        />
      ) : (
        <Flex justify="center">
          <Flex
            maxW={shouldBeSmallSize ? 'full' : '95vw'}
            overflowX="auto"
            sx={scrollBarStyles}>
            <Flex gridGap="20px" mt="30px" mb="50px" mx="35px">
              {myPacks.map((pack, idx) => (
                <Flex
                  key={pack.id + idx + selectedIndustry}
                  bg="#FFF"
                  zIndex={1}
                  w={elWidth}
                  h={elHeight}
                  borderRadius="20px"
                  transform="scale(1)"
                  overflow="hidden"
                  gridGap="20px"
                  transition="all 0.3s ease-in-out"
                  shadow="0 0 20px 0 rgba(0,0,0,0.08)"
                  _hover={_hover}
                  _active={_active}
                  // zIndex={idx === Math.floor(myPacks.length / 2) ? 2 : 1}
                  justify="center">
                  <Flex
                    p="20px"
                    pr="10px"
                    flex={1.2}
                    direction="column"
                    gridGap="10px"
                    align="center"
                    justify="center">
                    <Text
                      color="#413F3F"
                      fontSize={pack.name.length > 12 ? '14px' : '17px'}
                      fontWeight="bold"
                      textAlign="center"
                      noOfLines={3}>
                      {pack.name || ''}
                    </Text>
                    <Text color="#413F3F" fontSize="2.165rem" fontWeight="bold">
                      ${pack?.price?.toLocaleString()}
                    </Text>
                    <Text
                      color="#838383"
                      fontSize="13px"
                      fontWeight="400"
                      fontFamily="Poppins"
                      textAlign="center">
                      {pack?.description}
                    </Text>
                    <Button
                      bg={brandColor}
                      fontFamily="poppins"
                      _hover={{ bg: `${brandColor}95` }}
                      _active={{ bg: brandColor }}
                      onClick={() => onPackSelect(pack)}>
                      START A MISSION
                    </Button>
                  </Flex>
                  <Flex
                    p="20px"
                    pl="10px"
                    flex={1.5}
                    gridGap="10px"
                    direction="column"
                    marginTop={
                      pack?.lineItems?.length
                        ? `${Math.max(11 - pack.lineItems.length, 0) * 10}px`
                        : '0'
                    }
                    overflow="hidden">
                    {pack?.lineItems?.map((l, index) => (
                      <Flex key={l.name + l.price} gridGap="10px">
                        <Box
                          p="2px"
                          borderRadius="50px"
                          bg={`${brandColor}20`}
                          h="24px">
                          <MdCheck size="20px" color={brandColor} />
                        </Box>
                        <Flex flexDir="column" width={60} fontSize="14px">
                          <Text
                            noOfLines={3}
                            color="#838383"
                            fontSize="17px"
                            fontFamily="Poppins"
                            fontWeight={index === 0 ? 700 : 400} // Set fontWeight based on index
                            lineHeight="17px"
                            whiteSpace="break-spaces">
                            {l.count} - {l.name}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
      )}
      <MissionCreateModal ref={modalRef} />
      <UnableCreateMissionsModal
        ref={previewModeModalRef}
        cmsUrl={cms?.username}
      />
    </Flex>
  );
};

// &::-webkit-scrollbar {
//   width: 28px;
// }
// overflow-y: hidden;
// &::-webkit-scrollbar {
//   width: 8px;
// }
// &::-webkit-scrollbar-track {
//   background-color: #fff;
//   border-radius: 30px;
// }
// &::-webkit-scrollbar-thumb {
//   background: #92835c50;
//   border-radius: 30px;
// }
