import React, { FC, useEffect } from 'react';

import { Button } from '@chakra-ui/react';
import { Flex, Text } from '@chakra-ui/layout';

import { createEvent, createStore } from 'effector';

import { useCMS } from './Context';
import { useStore } from 'effector-react';

import { useLocation } from 'react-router-dom';

import { industriesMap } from 'constants/industriesMap';
import { MyPacks } from './MyPacks';

const scrollBarStyles = {
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#fff',
    borderRadius: '30px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#92835C50',
    borderRadius: '30px',
    overflow: 'auto',
  },
};
export const Industries: FC = () => {
  const { cms, brandColor } = useCMS();

  const location = useLocation();

  const selectedIndustry = useStore(SelectedIndustryStore);

  const industries = cms?.industries || [];

  useEffect(() => {
    const industry = industries[0];
    const formattedIndustry = industriesMap[industry]
      ? industriesMap[industry]
      : industry;

    selectIndustryEvent(formattedIndustry);
  }, []);

  const isPreview = location.pathname.includes('preview');

  return (
    <Flex
      mb="2px"
      minH="360px"
      direction="column"
      overflow="hidden"
      id="Packages"
      align="center">
      <Text
        mb={8}
        color="#222222"
        fontSize={{ base: '28px', md: '32px' }}
        userSelect="none"
        fontWeight="bold"
        textAlign="center">
        Industries we work with
      </Text>
      <Flex overflowX="auto" maxW="70vw" css={scrollBarStyles}>
        <Flex gridGap="15px" pb="30px">
          {industries.map((i) => {
            const isEqual =
              String(selectedIndustry).toLowerCase() ===
              String(i).toLowerCase();

            return (
              <Button
                key={i}
                size="lg"
                variant="outline"
                w="fit-content"
                overflow="auto"
                borderRadius="50px"
                textTransform="capitalize"
                color={isEqual ? '#fff' : brandColor}
                borderColor={brandColor}
                _hover={{ bg: `${brandColor}10` }}
                _active={{ bg: `${brandColor}30` }}
                bg={isEqual ? brandColor : undefined}
                onClick={() => {
                  const formattedIndustry = industriesMap[i]
                    ? industriesMap[i]
                    : i;

                  selectIndustryEvent(formattedIndustry);
                }}>
                {i.toLowerCase()}
              </Button>
            );
          })}
        </Flex>
      </Flex>
      <MyPacks isPreview={isPreview} />
    </Flex>
  );
};

export const selectIndustryEvent = createEvent<string>();
export const SelectedIndustryStore = createStore('').on(
  selectIndustryEvent,
  (_, selected) => selected,
);
